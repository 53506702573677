exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agradecimento-index-tsx": () => import("./../../../src/pages/agradecimento/index.tsx" /* webpackChunkName: "component---src-pages-agradecimento-index-tsx" */),
  "component---src-pages-baixe-o-app-mony-index-tsx": () => import("./../../../src/pages/baixe-o-app-mony/index.tsx" /* webpackChunkName: "component---src-pages-baixe-o-app-mony-index-tsx" */),
  "component---src-pages-como-funciona-index-tsx": () => import("./../../../src/pages/como-funciona/index.tsx" /* webpackChunkName: "component---src-pages-como-funciona-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-para-o-consumidor-index-tsx": () => import("./../../../src/pages/para-o-consumidor/index.tsx" /* webpackChunkName: "component---src-pages-para-o-consumidor-index-tsx" */),
  "component---src-pages-para-o-empresario-index-tsx": () => import("./../../../src/pages/para-o-empresario/index.tsx" /* webpackChunkName: "component---src-pages-para-o-empresario-index-tsx" */),
  "component---src-pages-politica-de-privacidade-index-tsx": () => import("./../../../src/pages/politica-de-privacidade/index.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-index-tsx" */),
  "component---src-pages-quero-contratar-index-tsx": () => import("./../../../src/pages/quero-contratar/index.tsx" /* webpackChunkName: "component---src-pages-quero-contratar-index-tsx" */)
}

